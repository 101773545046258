// Chakra imports
import React, { useEffect } from "react";
import {
  Center,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { RiBitCoinLine } from "react-icons/ri";
import useAxios from "axios-hooks";

// Custom components
import Card from "components/Card/Card.js";
import IconBox from "components/Icons/IconBox";

import { checkLogin } from "../../utils/authentication";
import { API_ROUTES, ROOT_API } from "utils/constant";
import SummariesCoinChart from "components/SummariesCoinChart";

const StatisticalData = (props) => {
  const { title, value, icon } = props;
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card minH="125px" bgColor="white" borderRadius="8px">
      <Text fontSize="xl" color={textColor} fontWeight="bold">
        {title}
      </Text>
      <Flex direction="row" mt={"16px"}>
        <Center>
          {icon}
          <Text fontSize="5xl" color={textColor} fontWeight="900">
            {value}
          </Text>
        </Center>
      </Flex>
    </Card>
  );
};

export default function Dashboard() {
  const isLoggedIn = checkLogin();
  const history = useHistory();

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push("/auth/signin");
    }
  }, [isLoggedIn, history]);

  const [{ data: summariesCoinData }] = useAxios(
    {
      method: "get",
      url: `${ROOT_API}${API_ROUTES.SUMMARIES_COIN}`,
    },
    { useCache: false }
  );
  const [{ data: summariesBuyCoinData }, getSummariesBuyCoinApi] = useAxios(
    {
      method: "get",
      url: `${ROOT_API}${API_ROUTES.SUMMARIES_BUY_COIN}`,
    },
    { useCache: false }
  );
  const [{ data: summariesSellCoinData }, getSummariesSellCoinApi] = useAxios(
    {
      method: "get",
      url: `${ROOT_API}${API_ROUTES.SUMMARIES_SELL_COIN}`,
    },
    { useCache: false }
  );

  return (
    <>
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing="24px"
          mb="20px"
          mt="20px"
        >
          <StatisticalData
            title="Tổng số Coin đã bán"
            value={summariesCoinData?.data?.dataTotalSellCoin || 0}
            icon={
              <IconBox
                borderRadius="50%"
                as="box"
                h={"45px"}
                w={"45px"}
                mr={"16px"}
              >
                <RiBitCoinLine size={"45"} />
              </IconBox>
            }
          />
          <StatisticalData
            title="Tổng số Coin đã mua"
            value={summariesCoinData?.data?.dataTotalBuyCoin || 0}
            icon={
              <IconBox
                borderRadius="50%"
                as="box"
                h={"45px"}
                w={"45px"}
                mr={"16px"}
              >
                <RiBitCoinLine size={"45"} />
              </IconBox>
            }
          />
        </SimpleGrid>
        <SimpleGrid
          columns={{ sm: 1, "2xl": "2" }}
          spacing="24px"
          mb="20px"
          mt="20px"
          background="white"
          py="32px"
          borderRadius={"10px"}
        >
          <SummariesCoinChart
            title="Số lượng Coin đã bán"
            labelChart="Số lượng Coin"
            dataChart={summariesSellCoinData?.data?.dataTotalSellCoin}
            getNewData={(startDate, endDate) => {
              getSummariesBuyCoinApi({ params: { startDate, endDate } });
            }}
          />
          <SummariesCoinChart
            title="Số lượng Coin đã mua"
            labelChart="Số lượng Coin"
            dataChart={summariesBuyCoinData?.data?.dataTotalBuyCoin}
            getNewData={(startDate, endDate) => {
              getSummariesSellCoinApi({ params: { startDate, endDate } });
            }}
          />
        </SimpleGrid>
      </Flex>
    </>
  );
}
